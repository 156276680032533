<template>
  <div class="view">
    <div class="view-header" v-if="pageTitle">
      <div class="view-header__footer">
        <Btn
          class="back-btn"
          :to="{ name: 'users' }"
          round
          grow
          icon="arrow-left"
        />
        <h1 class="page-title">{{ pageTitle }} - {{ profile.designation }}</h1>
      </div>
    </div>

    <div class="view-body">
      <Section v-if="profile">
        <Container>
          <div class="grid">
            <div class="tmp-col">
              <SelectExtended
                id="profils"
                v-model="activeProfile"
                :items="profiles"
                label="Profil de droits"
                optionKey="id"
                optionValue="designation"
              />
            </div>
          </div>
        </Container>
      </Section>

      <Section>
        <Container>
          <div
            class="permission-group"
            :class="{
              'permission-group--child': permission.isChild,
            }"
            v-for="permission in permissions"
            :key="permission.uid"
          >
            <div
              class="permission-block permission-block--title"
              :class="{
                'permission-block--disabled': false,
              }"
            >
              <SvgIcon
                :name="permission.allIsChecked ? 'check' : 'times'"
                size="xs"
              />
              <h2 class="h4 permission-group-title">
                {{ permission.designation }}
              </h2>
            </div>

            <div v-for="droits in permission.droits" :key="droits.uid">
              <div
                class="permission-block"
                :class="{
                  'permission-block--disabled': !isChecked(droits.uid),
                }"
              >
                <SvgIcon
                  :name="isChecked(droits.uid) ? 'check' : 'times'"
                  size="xs"
                />
                <span>{{ droits.designation }}</span>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </div>
  </div>
</template>

<script>
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import Btn from '@/components/base/Btn.vue'
import SvgIcon from '@/components/base/SvgIcon.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'

export default {
  name: 'UserProfilView',

  components: {
    Section,
    Container,
    Btn,
    SvgIcon,
    SelectExtended,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      user: {},
      userid: null,
      profile: [],
      profiles: [],
      activeProfile: {},
      renderEntity: '',
      permissions: [],
      profilePermissions: [],
      profileEntityTypes: [],
    }
  },

  created() {
    this.userid = this.$route.params.userid
    this.getUser()
    this.getPermissions()
  },

  watch: {
    activeProfile() {
      this.getProfile()
      this.getProfilePermissions()
      this.getProfileEntityTypes()
    },
  },

  methods: {
    async getUser() {
      await this.fetchService.get(`utilisateur/${this.userid}`, {}).then(
        (response) => {
          this.user = response.data

          this.activeProfile = {
            key: this.user.profils[0].id,
            value: this.user.profils[0].designation,
          }

          this.profiles = response.data.profils.map((obj) => {
            const rObj = {}
            rObj.key = obj.id
            rObj.value = obj.designation
            return rObj
          })
          console.log(' this.profiles', this.profiles)
          this.getProfile()
          this.getProfilePermissions()
          this.getProfileEntityTypes()
        },
        (responseError) => {
          console.log('fetchError', responseError)
        },
      )
    },
    /* Profile */
    getProfile() {
      this.fetchService.get(`profil/${this.activeProfile.key}`).then(
        (response) => {
          this.profile = response.data
        },
        (error) => {
          console.log(error)
        },
      )
    },

    /* Permissions categories & data */
    getPermissions() {
      this.fetchService.get('dictionnaire/categorie/droit').then(
        (response) => {
          this.permissions = response.data

          const array = []
          this.permissions.forEach((permission) => {
            array.push(permission)
            if (permission.enfants.length > 0) {
              permission.enfants.forEach((enfant) => {
                // eslint-disable-next-line no-param-reassign
                enfant.isChild = true

                array.push(enfant)
              })
            }
          })

          this.permissions = array
        },
        (error) => {
          console.log(error)
        },
      )
    },

    /* Profile permissions */
    getProfilePermissions() {
      this.fetchService.get(`profil/${this.activeProfile.key}/droit`).then(
        (response) => {
          this.profilePermissions = response.data

          // In the object permission
          this.permissions.forEach((permission) => {
            // Loop on the permissions
            permission.droits.forEach((droit) => {
              // In the profile permission
              this.profilePermissions.forEach((profilePermission) => {
                // Check if the profile permission is present in the permission object
                if (profilePermission.uid === droit.uid) {
                  // eslint-disable-next-line no-param-reassign
                  droit.isChecked = true
                }
              })
            })

            // Check if all permissions in a group is checked
            const allIsChecked = permission.droits.every(
              (droit) => droit.isChecked === true,
            )

            if (allIsChecked) {
              // eslint-disable-next-line no-param-reassign
              permission.allIsChecked = true
            }
          })
        },

        (error) => {
          console.log(error)
        },
      )
    },

    getProfileEntityTypes() {
      this.fetchService
        .get(`profil/${this.activeProfile.key}/entite_type`)
        .then(
          (response) => {
            this.profileEntityTypes = response.data
              .map((entity) => entity.designation)
              .join(', ')
          },
          (error) => {
            console.log(error)
          },
        )
    },

    /**
     * Checks if the profile contains the permission
     *
     * @param uid
     * @returns {boolean}
     */
    isChecked(uid) {
      return this.profilePermissions
        .map((permission) => permission.uid)
        .includes(uid)
    },
  },
}
</script>

<style lang="scss" scoped>
.permission-group {
  margin-bottom: $gutter;
  @include shadow(1);
  border-radius: $border-radius-base;
}

.permission-group-title {
  margin-bottom: 0;
}

.permission-block {
  padding: $gutter-half;
  border-top: 1px solid $color-gray-lightest;
  display: flex;
  align-items: center;

  .icon {
    margin-right: $gutter-half;
    color: $color-primary;
  }

  span {
    font-weight: $font-weight-semibold;
  }
}

.permission-group--child {
  margin-left: $gutter;
}

.permission-block--disabled {
  color: $color-gray-light;

  .icon {
    color: $color-gray-light;
  }

  span {
    font-weight: $font-weight-normal;
  }
}

.permission-block--title {
  background-color: $color-primary;
  color: white;
  border-top-left-radius: $border-radius-base;
  border-top-right-radius: $border-radius-base;

  .icon {
    color: white;
  }

  &.permission-block--disabled {
    background-color: $color-gray-lighter;
    color: $body-color-base;

    .icon {
      color: $body-color-base;
    }
  }
}
</style>
